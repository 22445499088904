import { persisted } from 'svelte-persisted-store';

export const gdprConsent = persisted('gdprConsent', {
	consent: null
});

export const giveConsent = () => {
	gdprConsent.update((gdprConsent) => {
		gdprConsent.consent = true;
		return gdprConsent;
	});
};

export const revokeConsent = () => {
	gdprConsent.update((gdprConsent) => {
		gdprConsent.consent = false;
		return gdprConsent;
	});
};
