<svelte:head>
	<script async src="https://www.googletagmanager.com/gtag/js?id=G-38FR1Y9T63"></script>
	<script>
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}

		gtag('consent', 'default', {
			ad_storage: 'denied',
			ad_user_data: 'denied',
			ad_personalization: 'denied',
			analytics_storage: 'denied'
		});

		gtag('js', new Date());

		gtag('config', 'G-38FR1Y9T63');
	</script>
</svelte:head>
