<script>
	import { gdprConsent, giveConsent, revokeConsent } from '$lib/stores/gdprConsent';
	import { slide } from 'svelte/transition';
	import { onMount } from 'svelte';

	let loading = true;
	let showBanner = false;
	$: consentGiven = $gdprConsent.consent;

	function allConsentGranted() {
		if (window.gtag) {
			gtag('consent', 'update', {
				ad_user_data: 'granted',
				ad_personalization: 'granted',
				ad_storage: 'granted',
				analytics_storage: 'granted'
			});
		}
	}

	$: if (consentGiven === true) {
		allConsentGranted();
	}

	onMount(() => {
		if (consentGiven === null) {
			setTimeout(() => {
				loading = false;
				showBanner = true;
			}, 1000);
		}
	});

	const handleAccept = () => {
		giveConsent();
		loading = true;
	};

	const handleReject = () => {
		revokeConsent();
		loading = true;
	};
</script>

{#if loading}
	<div />
{:else if showBanner}
	<div class="gdpr-banner-container" transition:slide|global={{ duration: 250 }}>
		<div class="top-row">
			<span
				>🍪 We use cookies to enhance your browsing experience. Learn more about our <a
					href="/privacy">privacy policy</a
				>.</span
			>
		</div>
		<div class="bottom-row">
			<button class="reject-btn" on:click={handleReject}>Reject</button>
			<button class="accept-btn" on:click={handleAccept}>Accept</button>
		</div>
	</div>
{/if}

<style>
	.gdpr-banner-container {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		padding: 1rem;
		text-align: center;
		border-top: 2px solid #000;
		z-index: 1000;
	}

	.accept-btn {
		background-color: #000;
		color: #fff;
		border: none;
		padding: 0.5rem 1rem;
		margin: 1rem;
		cursor: pointer;
	}

	a {
		color: #000;

		&:hover {
			text-decoration-thickness: 2px;
		}
	}

	.reject-btn {
		background-color: #fff;
		color: #000;
		border: 2px solid #000;
		padding: 0.5rem 1rem;
		margin: 1rem;
		cursor: pointer;
	}

	.top-row {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.bottom-row {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
